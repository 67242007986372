<template lang="">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="request">Sorgulama Yapıldı mı?</label>
        <select name="" id="request" class="form-control">
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="beyan">Beyanname Verildi mi?</label>
        <select name="" id="beyan" class="form-control">
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="belediye">Belediye Adı</label>
        <input type="text" class="form-control" id="belediye" />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="sicil">Sicil No</label>
        <input type="text" class="form-control" id="sicil" />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="vergi">Banka adına Vergi Borcu Kaldı Mı?</label>
        <select name="" id="vergi" class="form-control">
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="ceza">Ödenen Vergi ve Ceza Tutarı</label>
        <input type="text" class="form-control" id="ceza" />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="kalan">Kalan Vergi ve Ceza Tutarı</label>
        <input type="text" class="form-control" id="kalan" />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="islem">İşlem Yaptırıldığı Tarih</label>
        <input type="date" class="form-control" id="islem" />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="bildirim">Bildirim Yapıldığı Tarih</label>
        <input type="date" class="form-control" id="bildirim" />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="aciklama">Açıklama</label>
        <textarea
          name=""
          id="aciklama"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group d-flex">
        <button class="btn btn-primary" style="margin-left: auto">
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>
    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
   
        data(){
            return{

            }

        },
        mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Emlak Beyan Detay", route: "/declaration/detail" },
      ]);
    },
}
</script>
<style lang=""></style>
